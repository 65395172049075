import { v4 as uuidv4 } from 'uuid';

export class PriceList {
    constructor(id, name, isBase, priceListItemId, childrenFare, adultFare, serviceFare) {
        this.id = id;
        this.name = name;
        this.isBase = isBase;
        this.priceListItemId = priceListItemId;
        this.childrenFare = childrenFare;
        this.adultFare = adultFare;
        this.serviceFare = serviceFare;

        this.guid = uuidv4();
    }
}

export function generateBasePriceList() {
    return new PriceList(0, 'BASE', true, 0, '', '', '');
}