import { v4 as uuidv4 } from 'uuid';

export class ServiceExpense {
    constructor(id, serviceId, adultFare, childrenFare, standFare, gasolineFare, vehicleFare, breakfastFare, travelExpensesFare) {
        this.id = id;
        this.serviceId = serviceId;
        this.adultFare = adultFare;
        this.childrenFare = childrenFare;
        this.standFare = standFare;
        this.gasolineFare = gasolineFare;
        this.vehicleFare = vehicleFare;
        this.breakfastFare = breakfastFare;
        this.travelExpensesFare = travelExpensesFare;
        this.guid = uuidv4();
    }
}

export function generateDefaultExpenseList() {
    return new ServiceExpense(0, 0, '', '', '', '', '', '', '');
}