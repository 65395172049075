var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('b-card',{staticClass:"white-bg",scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"outline-secondary","to":{ name: 'ServiceIndex' }}},[_c('font-awesome-icon',{attrs:{"icon":"angle-left"}}),_vm._v(" Regresar")],1),(_vm.service.id)?_c('b-button',{staticClass:"mr-2",attrs:{"variant":"secondary","to":{ name: 'ServiceShow', params: { id: _vm.service.id }}}},[_c('font-awesome-icon',{attrs:{"icon":"search"}}),_vm._v(" Detalle ")],1):_vm._e(),_c('b-button',{attrs:{"type":"submit","form":"user-form","variant":"primary"}},[_c('font-awesome-icon',{attrs:{"icon":"save"}}),_vm._v(" Guardar")],1)],1)]},proxy:true}])},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-form',{attrs:{"id":"user-form"},on:{"submit":function($event){$event.preventDefault();return passes(_vm.saveService)}}},[_c('h5',[_vm._v("Datos generales")]),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{staticClass:"text-left",attrs:{"cols":"12","md":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nombre","invalid-feedback":"Ingresa el nombre"}},[_c('b-form-input',{attrs:{"autocomplete":"false","placeholder":"Nombre","state":errors[0] ? false : null},model:{value:(_vm.service.name),callback:function ($$v) {_vm.$set(_vm.service, "name", $$v)},expression:"service.name"}})],1)]}}],null,true)})],1),_c('b-col',{staticClass:"text-left",attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Descripción"}},[_c('b-form-textarea',{attrs:{"placeholder":"Descripción","rows":"3","max-rows":"8"},model:{value:(_vm.service.description),callback:function ($$v) {_vm.$set(_vm.service, "description", $$v)},expression:"service.description"}})],1)],1),_c('b-col',{staticClass:"text-left",attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Categoría","invalid-feedback":"Selecciona la categoría"}},[_c('v-select',{class:{'is-invalid': errors.length > 0},attrs:{"options":_vm.entityTypes,"placeholder":"Selecciona la categoría","reduce":function (entityType) { return entityType.code; }},model:{value:(_vm.service.entityType),callback:function ($$v) {_vm.$set(_vm.service, "entityType", $$v)},expression:"service.entityType"}})],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipo","invalid-feedback":"Selecciona al menos un tipo","state":errors[0] ? false : null}},[_c('b-form-checkbox',{attrs:{"inline":""},model:{value:(_vm.service.isCollective),callback:function ($$v) {_vm.$set(_vm.service, "isCollective", $$v)},expression:"service.isCollective"}},[_vm._v(" Colectivo ")]),_c('b-form-checkbox',{attrs:{"inline":""},model:{value:(_vm.service.isExclusive),callback:function ($$v) {_vm.$set(_vm.service, "isExclusive", $$v)},expression:"service.isExclusive"}},[_vm._v(" Exclusivo ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Horario","invalid-feedback":"Selecciona un horario","state":errors[0] ? false : null}},[_c('b-form-radio-group',{attrs:{"options":_vm.serviceDurationTypes},model:{value:(_vm.service.durationType),callback:function ($$v) {_vm.$set(_vm.service, "durationType", $$v)},expression:"service.durationType"}})],1)]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{staticClass:"text-left",attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Duración (días)","invalid-feedback":_vm.service.duration.trim() === '' ? 'Ingresa la duración' : 'Ingresa una duración válida'}},[_c('b-form-input',{attrs:{"autocomplete":"false","placeholder":"Duración (días)","state":errors[0] ? false : null},model:{value:(_vm.service.duration),callback:function ($$v) {_vm.$set(_vm.service, "duration", $$v)},expression:"service.duration"}})],1)]}}],null,true)})],1),(_vm.service.durationType === 'SPECIFIC')?_c('b-col',{staticClass:"text-left",attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":{required: true, regex: /\d\d:\d\d/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Hora de inicio","state":errors[0] ? false : null,"invalid-feedback":_vm.service.startTime.trim() === '' ? 'Ingresa la hora de inicio' : 'Ingresa una hora de inicio válida'}},[_c('b-form-input',{staticClass:"inputmask",attrs:{"autocomplete":"false","placeholder":"Hora de inicio","data-inputmask":"'alias': 'datetime', 'inputFormat': 'HH:MM'"},model:{value:(_vm.service.startTime),callback:function ($$v) {_vm.$set(_vm.service, "startTime", $$v)},expression:"service.startTime"}})],1)]}}],null,true)})],1):_vm._e(),(_vm.service.durationType === 'SPECIFIC')?_c('b-col',{staticClass:"text-left",attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":{required: true, regex: /\d\d:\d\d/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Hora de término","state":errors[0] ? false : null,"invalid-feedback":_vm.service.endTime.trim() === '' ? 'Ingresa la hora de término' : 'Ingresa una hora de término válida'}},[_c('b-form-input',{staticClass:"inputmask",attrs:{"autocomplete":"false","placeholder":"Hora de término","data-inputmask":"'alias': 'datetime', 'inputFormat': 'HH:MM'"},model:{value:(_vm.service.endTime),callback:function ($$v) {_vm.$set(_vm.service, "endTime", $$v)},expression:"service.endTime"}})],1)]}}],null,true)})],1):_vm._e()],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[(_vm.service.isCollective)?[_c('h5',[_vm._v("Tarifa por pasajero")]),_c('b-table-simple',{attrs:{"bordered":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticStyle:{"text-align":"right"}},[_vm._v("Adultos")]),_c('b-th',{staticStyle:{"text-align":"right"}},[_vm._v("Niños")])],1)],1),_c('b-tbody',[_c('b-tr',[_c('b-td',{staticClass:"text-right"},[_c('ValidationProvider',{attrs:{"rules":"required|double"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"text-align":"right"},attrs:{"placeholder":((_vm.service.basePriceList.name) + " (Adultos)"),"rows":"3","max-rows":"8","state":errors[0] ? false : null},model:{value:(_vm.service.basePriceList.adultFare),callback:function ($$v) {_vm.$set(_vm.service.basePriceList, "adultFare", $$v)},expression:"service.basePriceList.adultFare"}})]}}],null,true)})],1),_c('b-td',[_c('ValidationProvider',{attrs:{"rules":"required|double"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"text-align":"right"},attrs:{"placeholder":((_vm.service.basePriceList.name) + " (Niños)"),"rows":"3","max-rows":"8","state":errors[0] ? false : null},model:{value:(_vm.service.basePriceList.childrenFare),callback:function ($$v) {_vm.$set(_vm.service.basePriceList, "childrenFare", $$v)},expression:"service.basePriceList.childrenFare"}})]}}],null,true)})],1)],1)],1)],1)]:_vm._e(),(_vm.service.isExclusive)?[_c('h5',[_vm._v("Tarifa por servicio exclusivo")]),_c('b-table-simple',{attrs:{"bordered":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticStyle:{"text-align":"right"}},[_vm._v("Costo")])],1)],1),_c('b-tbody',[_c('b-tr',[_c('b-td',{staticClass:"text-right"},[_c('ValidationProvider',{attrs:{"rules":"required|double"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"text-align":"right"},attrs:{"placeholder":((_vm.service.basePriceList.name) + " (Servicio)"),"rows":"3","max-rows":"8","state":errors[0] ? false : null},model:{value:(_vm.service.basePriceList.serviceFare),callback:function ($$v) {_vm.$set(_vm.service.basePriceList, "serviceFare", $$v)},expression:"service.basePriceList.serviceFare"}})]}}],null,true)})],1)],1)],1)],1)]:_vm._e()],2),_c('b-col',{attrs:{"md":"6"}},[_c('h5',[_vm._v("Gastos")]),_c('b-table-simple',{attrs:{"bordered":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',[_vm._v("Nombre")]),_c('b-th',[_vm._v("Tipo")]),_c('b-th',{staticStyle:{"text-align":"right"}},[_vm._v("Costo Unitario")])],1)],1),_c('b-tbody',[_c('b-tr',[_c('b-th',[_vm._v("Entrada Adulto")]),_c('b-td',[_vm._v("Por pasajero")]),_c('b-td',[_c('ValidationProvider',{attrs:{"rules":"required|double"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"text-align":"right"},attrs:{"placeholder":"Entrada Adulto","rows":"3","max-rows":"8","state":errors[0] ? false : null},model:{value:(_vm.service.defaultExpenseList.adultFare),callback:function ($$v) {_vm.$set(_vm.service.defaultExpenseList, "adultFare", $$v)},expression:"service.defaultExpenseList.adultFare"}})]}}],null,true)})],1)],1),_c('b-tr',[_c('b-th',[_vm._v("Entrada Niño")]),_c('b-td',[_vm._v("Por pasajero")]),_c('b-td',[_c('ValidationProvider',{attrs:{"rules":"required|double"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"text-align":"right"},attrs:{"placeholder":"Entrada Niño","rows":"3","max-rows":"8","state":errors[0] ? false : null},model:{value:(_vm.service.defaultExpenseList.childrenFare),callback:function ($$v) {_vm.$set(_vm.service.defaultExpenseList, "childrenFare", $$v)},expression:"service.defaultExpenseList.childrenFare"}})]}}],null,true)})],1)],1),_c('b-tr',[_c('b-th',[_vm._v("Casetas")]),_c('b-td',[_vm._v("Por servicio")]),_c('b-td',[_c('ValidationProvider',{attrs:{"rules":"required|double"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"text-align":"right"},attrs:{"placeholder":"Casetas","rows":"3","max-rows":"8","state":errors[0] ? false : null},model:{value:(_vm.service.defaultExpenseList.standFare),callback:function ($$v) {_vm.$set(_vm.service.defaultExpenseList, "standFare", $$v)},expression:"service.defaultExpenseList.standFare"}})]}}],null,true)})],1)],1),_c('b-tr',[_c('b-th',[_vm._v("Gasolina")]),_c('b-td',[_vm._v("Por servicio")]),_c('b-td',[_c('ValidationProvider',{attrs:{"rules":"required|double"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"text-align":"right"},attrs:{"placeholder":"Gasolina","rows":"3","max-rows":"8","state":errors[0] ? false : null},model:{value:(_vm.service.defaultExpenseList.gasolineFare),callback:function ($$v) {_vm.$set(_vm.service.defaultExpenseList, "gasolineFare", $$v)},expression:"service.defaultExpenseList.gasolineFare"}})]}}],null,true)})],1)],1),_c('b-tr',[_c('b-th',[_vm._v("Desayuno")]),_c('b-td',[_vm._v("Por pasajero")]),_c('b-td',[_c('ValidationProvider',{attrs:{"rules":"required|double"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"text-align":"right"},attrs:{"placeholder":"Desayunos","rows":"3","max-rows":"8","state":errors[0] ? false : null},model:{value:(_vm.service.defaultExpenseList.breakfastFare),callback:function ($$v) {_vm.$set(_vm.service.defaultExpenseList, "breakfastFare", $$v)},expression:"service.defaultExpenseList.breakfastFare"}})]}}],null,true)})],1)],1),_c('b-tr',[_c('b-th',[_vm._v("Viaticos")]),_c('b-td',[_vm._v("Por servicio")]),_c('b-td',[_c('ValidationProvider',{attrs:{"rules":"required|double"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"text-align":"right"},attrs:{"placeholder":"Viaticos","rows":"3","max-rows":"8","state":errors[0] ? false : null},model:{value:(_vm.service.defaultExpenseList.travelExpensesFare),callback:function ($$v) {_vm.$set(_vm.service.defaultExpenseList, "travelExpensesFare", $$v)},expression:"service.defaultExpenseList.travelExpensesFare"}})]}}],null,true)})],1)],1)],1)],1)],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }