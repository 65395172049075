<template>
    <b-overlay :show="isLoading">
        <b-card class="white-bg">
            <ValidationObserver ref="observer" v-slot="{ passes }">
                <b-form @submit.prevent="passes(saveService)" id="user-form">
                    <h5>Datos generales</h5>
                    <b-row class="mb-2">
                        <b-col cols="12" md="12" class="text-left">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Nombre" invalid-feedback="Ingresa el nombre">
                                    <b-form-input v-model="service.name" autocomplete="false" placeholder="Nombre"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="12" class="text-left">
                            <b-form-group label="Descripción">
                                <b-form-textarea placeholder="Descripción" rows="3" max-rows="8" v-model="service.description"></b-form-textarea>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4" class="text-left">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Categoría" invalid-feedback="Selecciona la categoría">
                                    <v-select v-model="service.entityType" :options="entityTypes" placeholder="Selecciona la categoría"
                                              :class="{'is-invalid': errors.length > 0}" :reduce="entityType => entityType.code" ></v-select>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="4">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Tipo" invalid-feedback="Selecciona al menos un tipo" :state="errors[0] ? false : null">
                                    <b-form-checkbox v-model="service.isCollective" inline>
                                        Colectivo
                                    </b-form-checkbox>
                                    <b-form-checkbox v-model="service.isExclusive" inline>
                                        Exclusivo
                                    </b-form-checkbox>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="4">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Horario" invalid-feedback="Selecciona un horario" :state="errors[0] ? false : null">
                                    <b-form-radio-group v-model="service.durationType" :options="serviceDurationTypes"></b-form-radio-group>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row class="mb-2">
                        <b-col cols="12" md="4" class="text-left">
                            <ValidationProvider rules="required|numeric" v-slot="{ errors }">
                                <b-form-group label="Duración (días)" :invalid-feedback="service.duration.trim() === '' ? 'Ingresa la duración' : 'Ingresa una duración válida'">
                                    <b-form-input v-model="service.duration" autocomplete="false" placeholder="Duración (días)"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="4" class="text-left" v-if="service.durationType === 'SPECIFIC'">
                            <ValidationProvider :rules="{required: true, regex: /\d\d:\d\d/ }" v-slot="{ errors }">
                                <b-form-group label="Hora de inicio" :state="errors[0] ? false : null"
                                              :invalid-feedback="service.startTime.trim() === '' ? 'Ingresa la hora de inicio' : 'Ingresa una hora de inicio válida'">
                                    <b-form-input autocomplete="false" placeholder="Hora de inicio" v-model="service.startTime" data-inputmask="'alias': 'datetime', 'inputFormat': 'HH:MM'" class="inputmask"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="4" class="text-left" v-if="service.durationType === 'SPECIFIC'">
                            <ValidationProvider :rules="{required: true, regex: /\d\d:\d\d/ }" v-slot="{ errors }">
                                <b-form-group label="Hora de término" :state="errors[0] ? false : null"
                                              :invalid-feedback="service.endTime.trim() === '' ? 'Ingresa la hora de término' : 'Ingresa una hora de término válida'">
                                    <b-form-input autocomplete="false" placeholder="Hora de término" v-model="service.endTime" data-inputmask="'alias': 'datetime', 'inputFormat': 'HH:MM'" class="inputmask"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col md="6">
                            <template v-if="service.isCollective">
                                <h5>Tarifa por pasajero</h5>
                                <b-table-simple bordered>
                                    <b-thead>
                                        <b-tr>
                                            <b-th style="text-align: right">Adultos</b-th>
                                            <b-th style="text-align: right">Niños</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr>
                                            <b-td class="text-right">
                                                <ValidationProvider rules="required|double" v-slot="{ errors }">
                                                    <b-form-input :placeholder="`${service.basePriceList.name} (Adultos)`" rows="3" max-rows="8" style="text-align: right"
                                                                  v-model="service.basePriceList.adultFare" :state="errors[0] ? false : null"></b-form-input>
                                                </ValidationProvider>
                                            </b-td>
                                            <b-td>
                                                <ValidationProvider rules="required|double" v-slot="{ errors }">
                                                    <b-form-input :placeholder="`${service.basePriceList.name} (Niños)`" rows="3" max-rows="8" style="text-align: right"
                                                                  v-model="service.basePriceList.childrenFare" :state="errors[0] ? false : null"></b-form-input>
                                                </ValidationProvider>
                                            </b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </template>
                            <template v-if="service.isExclusive">
                                <h5>Tarifa por servicio exclusivo</h5>
                                <b-table-simple bordered>
                                    <b-thead>
                                        <b-tr>
                                            <b-th style="text-align: right">Costo</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr>
                                            <b-td class="text-right">
                                                <ValidationProvider rules="required|double" v-slot="{ errors }">
                                                    <b-form-input :placeholder="`${service.basePriceList.name} (Servicio)`" rows="3" max-rows="8" style="text-align: right"
                                                                  v-model="service.basePriceList.serviceFare" :state="errors[0] ? false : null"></b-form-input>
                                                </ValidationProvider>
                                            </b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </template>
                        </b-col>
                        <b-col md="6">
                            <h5>Gastos</h5>
                            <b-table-simple bordered>
                                <b-thead>
                                    <b-tr>
                                        <b-th>Nombre</b-th>
                                        <b-th>Tipo</b-th>
                                        <b-th style="text-align: right">Costo Unitario</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr>
                                        <b-th>Entrada Adulto</b-th>
                                        <b-td>Por pasajero</b-td>
                                        <b-td>
                                            <ValidationProvider rules="required|double" v-slot="{ errors }">
                                                <b-form-input :placeholder="`Entrada Adulto`" rows="3" max-rows="8" style="text-align: right"
                                                              v-model="service.defaultExpenseList.adultFare" :state="errors[0] ? false : null"></b-form-input>
                                            </ValidationProvider>
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>Entrada Niño</b-th>
                                        <b-td>Por pasajero</b-td>
                                        <b-td>
                                            <ValidationProvider rules="required|double" v-slot="{ errors }">
                                                <b-form-input :placeholder="`Entrada Niño`" rows="3" max-rows="8" style="text-align: right"
                                                              v-model="service.defaultExpenseList.childrenFare" :state="errors[0] ? false : null"></b-form-input>
                                            </ValidationProvider>
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>Casetas</b-th>
                                        <b-td>Por servicio</b-td>
                                        <b-td>
                                            <ValidationProvider rules="required|double" v-slot="{ errors }">
                                                <b-form-input :placeholder="`Casetas`" rows="3" max-rows="8" style="text-align: right"
                                                              v-model="service.defaultExpenseList.standFare" :state="errors[0] ? false : null"></b-form-input>
                                            </ValidationProvider>
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>Gasolina</b-th>
                                        <b-td>Por servicio</b-td>
                                        <b-td>
                                            <ValidationProvider rules="required|double" v-slot="{ errors }">
                                                <b-form-input :placeholder="`Gasolina`" rows="3" max-rows="8" style="text-align: right"
                                                              v-model="service.defaultExpenseList.gasolineFare" :state="errors[0] ? false : null"></b-form-input>
                                            </ValidationProvider>
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>Desayuno</b-th>
                                        <b-td>Por pasajero</b-td>
                                        <b-td>
                                            <ValidationProvider rules="required|double" v-slot="{ errors }">
                                                <b-form-input :placeholder="`Desayunos`" rows="3" max-rows="8" style="text-align: right"
                                                              v-model="service.defaultExpenseList.breakfastFare" :state="errors[0] ? false : null"></b-form-input>
                                            </ValidationProvider>
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>Viaticos</b-th>
                                        <b-td>Por servicio</b-td>
                                        <b-td>
                                            <ValidationProvider rules="required|double" v-slot="{ errors }">
                                                <b-form-input :placeholder="`Viaticos`" rows="3" max-rows="8" style="text-align: right"
                                                              v-model="service.defaultExpenseList.travelExpensesFare" :state="errors[0] ? false : null"></b-form-input>
                                            </ValidationProvider>
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </b-col>
                    </b-row>
                </b-form>
            </ValidationObserver>
            <template #footer>
                <div class="text-right">
                    <b-button variant="outline-secondary" :to="{ name: 'ServiceIndex' }" class="mr-2"><font-awesome-icon icon="angle-left"></font-awesome-icon> Regresar</b-button>
                    <b-button variant="secondary" :to="{ name: 'ServiceShow', params: { id: service.id }}" v-if="service.id" class="mr-2">
                        <font-awesome-icon icon="search"></font-awesome-icon> Detalle
                    </b-button>
                    <b-button type="submit" form="user-form" variant="primary"><font-awesome-icon icon="save"></font-awesome-icon> Guardar</b-button>
                </div>
            </template>
        </b-card>
    </b-overlay>
</template>

<script>
import { extend } from 'vee-validate';
import { required, double, numeric, regex } from 'vee-validate/dist/rules';
import { serviceUrl } from '@routes';
import * as constants from '@constants';
import { PriceList, generateBasePriceList } from '../../models/priceList';
import { ServiceExpense, generateDefaultExpenseList } from '../../models/serviceExpense';
import { formatCurrency } from '../../js/utilities';
import { timePickerLabels } from '../../i18n/timePickerI18n';
import Inputmask from 'inputmask';

extend('required', {
    ...required,
    message: 'Campo requerido'
});

extend('double', {
    ...double,
    message: 'Ingresa solo números y decimales'
});

extend('numeric', {
    ...numeric,
    message: 'Ingresa solo números'
});

extend('regex', {
    ...regex,
    message: 'Ingresa un valor válido'
});

export default {
    data() {
        return {
            service: {
                id: 0,
                name: '',
                description: '',
                isCollective: true,
                isExclusive: true,
                duration: '',
                basePriceList: generateBasePriceList(),
                entityType: '',
                durationType: 'ALL',
                startTime: '',
                endTime: '',
                defaultExpenseList: generateDefaultExpenseList()
            },
            swalTitle: 'Servicios',
            breadcrumb: {
                title: 'Servicios',
                path: [
                    {
                        name: 'ServiceIndex',
                        text: 'Servicios'
                    }
                ]
            },
            action: 'Nuevo',
            signature: null,
            oldSignature: null,
            isLoading: false,
            file: null,
            timePickerLabels: timePickerLabels.es,
            entityTypes: [
                { code: 'TOUR', label: 'Tour' },
                { code: 'TRANSFER', label: 'Traslado' }
            ],
            serviceDurationTypes: [
                { text: 'Todo el dia', value: 'ALL' },
                { text: 'Horario especifico', value: 'SPECIFIC' }
            ]
        };
    },
    created() {
        if (this.$route.name.includes('Edit')) {
            this.loadData();

            this.action = 'Modificar';
        }

        this.breadcrumb.path.push({
            name: this.$route.name,
            text: `${this.action}`
        });

        this.$emit('update-breadcrumb', this.breadcrumb);
    },
    methods: {
        loadData() {
            this.isLoading = true;

            this.axios.get(serviceUrl(this.$route.params.id)).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    let service = response.data.service;

                    this.service.id = service.id;
                    this.service.name = service.name;
                    this.service.description = service.description;
                    this.service.isCollective = service.isCollective;
                    this.service.isExclusive = service.isExclusive;
                    this.service.duration = service.duration.toString();
                    this.service.durationType = service.durationType;
                    this.service.entityType = service.entityType;
                    this.service.startTime = service.startTime;
                    this.service.endTime = service.endTime;

                    this.service.basePriceList = new PriceList(service.basePriceList.id, service.basePriceList.name, service.basePriceList.isBase,
                        service.basePriceList.priceListItemId, service.basePriceList.childrenFare, service.basePriceList.adultFare, service.basePriceList.serviceFare);
                    this.service.defaultExpenseList = new ServiceExpense(service.defaultExpenseList.id, service.defaultExpenseList.serviceId,
                        service.defaultExpenseList.adultFare, service.defaultExpenseList.childrenFare, service.defaultExpenseList.standFare,
                        service.defaultExpenseList.gasolineFare, service.defaultExpenseList.vehicleFare, service.defaultExpenseList.breakfastFare,
                        service.defaultExpenseList.travelExpensesFare);
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        saveService() {
            this.isLoading = true;

            this.axios.post(serviceUrl(), this.service).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: this.swalTitle,
                        variant: 'success'
                    });

                    this.$router.push({ name: 'ServiceShow', params: { id: response.data.id }});
                } else {
                    this.$bvToast.toast(response.data.message ?? 'Ocurrió un error al guardar los cambios.', {
                        title: this.swalTitle,
                        variant: 'danger'
                    });
                }
            }).catch(error => {
                console.error(error);

                this.$bvToast.toast('Ocurrió un error al guardar los cambios.', {
                    title: this.swalTitle,
                    variant: 'danger'
                });
            }).finally(() => {
                this.isLoading = false;
            });
        }
    },
    computed: {
    },
    watch: {
        'service.durationType'() {
            if (this.service.durationType === 'SPECIFIC') {
                this.$nextTick(() => {
                    Inputmask().mask(document.querySelectorAll('.inputmask'));
                });
            }
        }
    }
};
</script>

<style scoped>
</style>